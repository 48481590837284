import { styled } from "@mui/material";
import { getCountryFlag } from "helpers/address";
import { HTMLAttributes, useEffect, useMemo } from "react";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const Img = (props: LazyLoadImageProps) => (
  <StyledLazyLoadImage
    effect="blur"
    loading="lazy"
    alt={props.alt || "img"}
    {...props}
  />
);

export const FlagImg = ({
  country_code = "",
  ...rest
}: { country_code?: string } & HTMLAttributes<HTMLImageElement>) => {
  const imageUrl = useMemo(
    () => getCountryFlag(country_code).src,
    [country_code]
  );

  return <img src={imageUrl} alt="flag" {...rest} loading="lazy"/>;
};

interface PreloadImgProps {
  images: { url: string; prioriry?: boolean }[];
}

const preloadImage = (url: string, isHighPriority = false) => {
  const link = document.createElement("link");
  link.rel = "preload";
  link.as = "image";
  link.href = url;
  if (isHighPriority) {
    link.setAttribute("fetchpriority", "high");
  }
  document.head.appendChild(link);
};

export const PreloadImgs = (props: PreloadImgProps) => {
  const { images } = props;
  useEffect(() => {
    const preloadAllImages = async () => {
      try {
        await Promise.all(
          images.map((src) => preloadImage(src.url, src.prioriry))
        );
      } catch (error) {
        console.error(error);
      }
    };
    if (images.length) {
      preloadAllImages();
    }
  }, [images]);
  return null;
};

const StyledLazyLoadImage = styled(LazyLoadImage)({
  display: "flex",
});
