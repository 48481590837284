import { useEffect, useMemo } from "react";
import { Typography, Grid, Divider, Stack, StackProps } from "@mui/material";

import { StyledFooter } from "./index.styles";
import FreshdiLogo from "Components/FreshdiLogo";
import IntlMsg from "Components/IntlMsg";
import Row from "Components/Row";
import { PATHS } from "../../constants/routes";
import { useIntl } from "react-intl";
import FooterEnterprise from "Components/Footer/FooterEnterprise";
import { ALink } from "Components/link";
import useBreakpoints from "hooks/useBreakpoint";

const Footer = () => {
  const { isMobile } = useBreakpoints();
  const footercolumns = useMemo(
    () => [
      {
        label: "GetSupport",
        children: [
          {
            label: "FAQs",
            href: PATHS.buyerFAQs,
          },
          {
            label: "ContactUs",
            href: PATHS.contactUs,
          },
          // {
          //   label: "ReportAbuse",
          //   href: null,
          // },
        ],
      },
      {
        label: "FeaturedServices",
        children: [
          {
            label: "Ai Agent",
            href: PATHS.aiAgent,
          },
        ],
      },
      {
        label: "SellOnFreshdi",
        children: [
          {
            label: "buying_leads",
            href: PATHS.buyleads,
          },
          {
            label: "SellerCentral",
            href: PATHS.supplierCenter,
          },
          {
            label: "membership_program",
            href: PATHS.supplierPricing,
          },
        ],
      },
      {
        label: "SourceOnFreshdi",
        children: [
          {
            label: "QuickPostRFQ",
            href: PATHS.postRfq,
          },
          {
            label: "BuyerCentral",
            href: PATHS.buyerCenter,
          },
        ],
      },
      ...(!isMobile
        ? [
            {
              component: () => (
                <Stack spacing={2}>
                  <FreshdiLogo />
                  <Typography>contact@freshdi.com</Typography>
                </Stack>
              ),
            },
          ]
        : []),
    ],
    []
  );

  return (
    <footer>
      <FooterEnterprise />
      <Divider />
      <StyledFooter sx={{ py: 4 }}>
        <Grid container spacing={3} mb={2} className="footer_main">
          {footercolumns.map((data, index) => {
            let Component = data?.component;

            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={2.4}
                container
                gap={2}
                direction="column"
              >
                {data?.label && (
                  <Typography variant="body1" className="footer_title">
                    <IntlMsg id={data?.label} />
                  </Typography>
                )}
                {Component && <Component />}
                {data.children?.map((c, i) => (
                  <ALink key={i} className="footer_link" href={c?.href || ""}>
                    <Typography>
                      {c?.label && <IntlMsg id={c?.label} />}
                    </Typography>
                  </ALink>
                ))}
              </Grid>
            );
          })}
        </Grid>
        <Divider />
        <LastFooter att={{ mt: 2 }} />
      </StyledFooter>
    </footer>
  );
};

const DmcaBadge = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://images.dmca.com/Badges/DMCABadgeHelper.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <a
      href="//www.dmca.com/Protection/Status.aspx?ID=8e4c2732-7ad6-4436-a47e-a4f491c5732c"
      title="DMCA.com Protection Status"
      className="dmca-badge"
    >
      <img
        src="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=8e4c2732-7ad6-4436-a47e-a4f491c5732c"
        alt="DMCA.com Protection Status"
        style={{ height: 24, width: 121 }}
        loading="lazy"
      />
    </a>
  );
};

const LastFooter = ({ att }: { att: StackProps["sx"] }) => {
  const { isMobile } = useBreakpoints();
  const t = useIntl();
  const services = useMemo(
    () => [
      { label: "sitemap", href: PATHS.sitemap },
      { label: "UserAgreement", href: PATHS.termCondition },
      { label: "PrivacyPolicy", href: PATHS.policy },
      { label: "disclaimer", href: PATHS.disclaimers },
    ],
    []
  );

  const ServiceComponent = () => (
    <Row justifyContent={isMobile ? "flex-start" : "flex-end"} flex={1}>
      {services.map((service, i) => (
        <ALink key={i} href={service.href}>
          <Typography fontSize={12}>
            {t.formatMessage({ id: service.label })}
          </Typography>
        </ALink>
      ))}
    </Row>
  );

  return (
    <Grid
      className="lastestFooter"
      sx={{
        ...att,
      }}
      spacing={2}
      container
    >
      <Grid item xs={12} md={6}>
        <Stack gap={2}>
          {isMobile && (
            <>
              <ServiceComponent />
              <FreshdiLogo />
              <DmcaBadge />
            </>
          )}
          <Row>
            <Typography className="forFarmText">
              <IntlMsg
                id="CopyrightBelongTo"
                values={{ name: "Freshdi.com" }}
              />
            </Typography>
            {!isMobile && <DmcaBadge />}
          </Row>
        </Stack>
      </Grid>
      {!isMobile && (
        <Grid item xs={12} md={6}>
          <ServiceComponent />
        </Grid>
      )}
    </Grid>
  );
};

export default Footer;
