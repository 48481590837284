import { createTheme } from "@mui/material";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { COLORS } from "constants/layout";

export const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
    body1: {
      fontSize: 16,
      "@media (min-width: 600px)": {},
    },
    body2: {
      fontSize: 14,
      "@media (min-width: 600px)": {},
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: COLORS.green[600],
          "&.Mui-checked": {
            color: COLORS.green[600],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "var(--sub-color)",
          "&.Mui-checked": {
            color: "var(--sub-color)",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: COLORS.gray[200],
        },
      },
    },
  },
  cartSpacing: "12px",
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
});
