import { MenuItem } from "@mui/material";
import useSelectLanguage from "./useSelectLanguage";
import Menu from "Components/Menu";
import { convertLangToCountry, getCountryFlag } from "helpers/address";
import { Img } from "Components/Img";
import { useMemo } from "react";

const SelectLanguage = ({ buttonProps = {}, showLabel = true }) => {
  const { countries, language, onChangeLanguage, languageLabel } =
    useSelectLanguage();

  const currentCountryFlag = useMemo(
    () => getCountryFlag(convertLangToCountry(language)).src,
    [language]
  );

  return (
    <Menu
      buttonText={
        <>
          <Img
            src={currentCountryFlag}
            style={{ marginRight: showLabel ? 8 : 0, width: 20 }}
          />
          {showLabel && languageLabel}
        </>
      }
      menuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        transformOrigin: {
          horizontal: "right",
        },
      }}
      value={language}
      {...{ buttonProps }}
    >
      {countries?.map((coutry, index) => {
        let value = coutry?.value;
        return (
          <MenuItem
            onClick={() => onChangeLanguage(value)}
            key={index}
            selected={value === language}
          >
            <Img
              src={getCountryFlag(convertLangToCountry(value)).src}
              className="flag"
              style={{ marginRight: 8 }}
            />
            {coutry?.label}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default SelectLanguage;
