import { Client, Conversation } from "@twilio/conversations";
import { MobileChatDetailType } from "types/chat";

export const CHAT_ACTIONS = {
  setTwlToken: "SET_TWL_TOKEN",
  setTwlIdentify: "SET_TWL_IDENTIFY",
  addConverstations: "ADD_CONVERSTATIONS",
  setConverstations: "SET_CONVERSTATIONS",
  setChatClient: "SET_CHAT_CLIENT",
  setChatList: "SET_CHAT_LIST",
  reloadHeaderChat: "RELOAD_HEADER_CHAT",
  setMobileChatDetail: "SET_MOBILE_CHAT_DETAIL",
  openChatContainer: "OPEN_CHAT_CONTAINER",
  closeChatContainer: "CLOSE_CHAT_CONTAINER",
  IsOpen: "IS_OPEN",
  closeOpen: "CLOSE_OPEN"
};

export const setTwlToken = (data?: string) => {
  return {
    type: CHAT_ACTIONS.setTwlToken,
    payload: data,
  };
};

export const setTwlIdentify = (data?: string) => {
  return {
    type: CHAT_ACTIONS.setTwlIdentify,
    payload: data,
  };
};

export const setChatClient = (data: Client) => {
  return {
    type: CHAT_ACTIONS.setChatClient,
    payload: data,
  };
};

export const addChatConverstation = (data: Conversation[]) => {
  return {
    type: CHAT_ACTIONS.addConverstations,
    payload: data,
  };
};

export const setChatConverstations = (data: Conversation[]) => {
  return {
    type: CHAT_ACTIONS.setConverstations,
    payload: data,
  };
};

export const setChatList = (data: Conversation[]) => {
  return {
    type: CHAT_ACTIONS.setChatList,
    payload: data,
  };
};

export const onReloadHeaderChat = () => {
  return {
    type: CHAT_ACTIONS.reloadHeaderChat,
  };
};

export const setMobileChatDetail = (data: MobileChatDetailType) => {
  return {
    type: CHAT_ACTIONS.setMobileChatDetail,
    payload: data,
  };
};

export const onOpenChatContainer = (conversation?: Conversation) => {
  return {
    type: CHAT_ACTIONS.openChatContainer,
    payload: conversation,
  };
};

export const onCloseChatContainer = () => {
  return {
    type: CHAT_ACTIONS.closeChatContainer,
  };
};

export const onOpenChat = (isOpen: boolean) => {
  return {
    type: CHAT_ACTIONS.IsOpen,
  }
}

export const closeOpenChat = (isOpen: boolean) => {
  return {
    type: CHAT_ACTIONS.closeOpen,
  }
}
