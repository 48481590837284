import { Box, styled } from "@mui/material";
import { Text } from "Components/Text";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import Tooltip from "./Tooltip";
import { Img } from "Components/Img";
import { APP_ICONS } from "Assets/icons";

const BuyerVerfifyTooltip = ({
  data,
  isMenu,
}: {
  data?: { is_biz_verified?: number; is_verified_work_email?: number, isCard?: boolean };
  isMenu?:boolean
}) => {
  const t = useIntl();
  const isCard = data?.isCard ?? false;

  const levelTexts = useMemo(
    () =>
      [
        {
          label: "verify_work_email",
          show: data?.is_verified_work_email,
        },
        {
          label: "verify_business_documents",
          show: data?.is_biz_verified,
        },
      ].filter((text) => text.show),
    [data]
  );

  return (
    <Tooltip
      title={
        <StyledLevelTooltip>
          <Text>
            {t.formatMessage({
              id:
                levelTexts.length > 0
                  ? (isMenu? "buyer_is_verified_by_freshdi" :"suppliers_is_verified_by_freshdi")
                  : isMenu? "buyer_is_not_verified": "suppliers_is_not_verified",
            })}
          </Text>
          <ul>
            {levelTexts
              .filter((text) => text.show)
              .map((text, i) => (
                <li key={i}>
                  <Text>{t.formatMessage({ id: text.label })}</Text>
                </li>
              ))}
          </ul>
        </StyledLevelTooltip>
      }
    >
      <div style={{ lineHeight: "18px" }}>
        <Img
          src={APP_ICONS[levelTexts.length > 0 ? (isCard ? "verified_white" :"verified") : (isCard ? "unVerified_white" :"unverified")]}
          alt="icon"
          height={12}
        />
      </div>
    </Tooltip>
  );
};

const StyledLevelTooltip = styled(Box)({
  p: {
    fontSize: 10,
  },
  ul: {
    paddingInlineStart: 10,
  },
  "& ul li p": {},
});

export default BuyerVerfifyTooltip;
