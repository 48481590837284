import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";

import { theme } from "./theme";
import AppProvider from "providers/app";
import { legacy_createStore } from "redux";
import ReactGA from "react-ga4";
import { GA4_MEASUREMENT_ID } from "./constants/schemas";
import "./index.css";
import "./styles.scss";
import rootReducer from "redux/reducers";
import { lazy, Suspense } from "react";
import FreshdiLoading from "./Components/loading/FreshdiLoading"

const store = legacy_createStore(rootReducer);
const AppRouter = lazy(() => import("./router"));

const isProd = process.env.NODE_ENV === "production";
if (isProd) {
  ReactGA.initialize(GA4_MEASUREMENT_ID || "");
} else {
  ReactGA.initialize(GA4_MEASUREMENT_ID || "", {
    gaOptions: {
      debug_mode: true,
    },
    gtagOptions: {
      debug_mode: true,
    },
  });
}

// ReactGA.initialize(GA4_MEASUREMENT_ID || "");
export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <AppProvider>
            <Suspense fallback={<FreshdiLoading />}>
              <AppRouter />
            </Suspense>
          </AppProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}
