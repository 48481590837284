import { Box, Button, ButtonProps, styled } from "@mui/material";
import { memo } from "react";
import Row from "./Row";
import { MediaType } from "types/file";
import { checkFileIsImage, createDownloadLink } from "helpers/file";
import { AddCircleOutline, AttachFile } from "@mui/icons-material";
import { useLoading } from "providers/loading";
import { apiUploadFile } from "services/api/file";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { formatImgUrl } from "utils/helpers";
import { API_DOMAIN } from "constants/schemas";
import { useIntl } from "react-intl";
import { ArrowUpIcon } from "Assets/icons";

type Props = {
  medias?: MediaType[];
  setMedias?: (medias?: any) => void;
  inputId?: string;
  signle?: boolean;
  signleMedia?: string;
  disabled?: boolean;
};

const UploadFiles = ({
  medias = [],
  setMedias,
  inputId = "media-input",
  signle = false,
  signleMedia = "",
  disabled = false,
}: Props) => {
  const { setLoading } = useLoading();
  const onClickUploadFile = () => {
    if (!disabled) {
      document.getElementById(inputId)?.click();
    }
  };

  const handleFileChange = (event: any, type: string) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      let formData = new FormData();
      formData.append("file", file);
      apiUploadFile(formData)
        .then((res) => {
          let { file_url, name } = res.data?.message || {};
          let newMedias = undefined;
          if (signle) {
            setMedias?.(file_url);
          } else {
            let newMedia: MediaType = { url: file_url, file: name };
            newMedias = [...medias, newMedia];
            setMedias?.(newMedias);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const onClickClear = (e: any, i?: number) => {
    e.stopPropagation();
    if (signle) {
      setMedias?.(null);
    } else {
      const newMedias = medias.filter((_, id) => id !== i);
      setMedias?.(newMedias);
    }
  };

  const onClickMedia = (mediaUrl: string) => {
    if (!disabled) {
      if (mediaUrl) {
        let link = `${API_DOMAIN}${mediaUrl}`;
        createDownloadLink(link, mediaUrl);
      } else {
        onClickUploadFile();
      }
    }
  };

  return (
    <StyledRow flexWrap="wrap">
      {(signle ? [{ url: signleMedia }] : medias).map((media, i) => {
        if (media.url)
          return (
            <Box
              className="app_media_box"
              key={i}
              onClick={() => onClickMedia(media.url)}
            >
              {media.url ? (
                checkFileIsImage(media.url) ? (
                  <img src={formatImgUrl(media.url, 1)} loading="lazy" alt="media box"/>
                ) : (
                  <AttachFile />
                )
              ) : (
                signle && <AddCircleOutline />
              )}

              {media.url && !disabled && (
                <IoMdCloseCircleOutline
                  className="clear-icon"
                  onClick={(e) => onClickClear(e, i)}
                />
              )}
            </Box>
          );
        return <UploadButton onClick={onClickUploadFile} />;
      })}

      {!signle && <UploadButton onClick={onClickUploadFile} />}
      <input
        id={inputId}
        className="hidden"
        type="file"
        onChange={(e) => handleFileChange(e, "medias")}
      />
    </StyledRow>
  );
};

const UploadButton = (props: ButtonProps) => {
  const t = useIntl();

  return (
    <Button className="upload-btn" startIcon={<ArrowUpIcon />} {...props}>
      {t.formatMessage({ id: "upload_file" })}
    </Button>
  );
};

const StyledRow = styled(Row)({
  ".app_media_box": {
    position: "relative",
  },
  ".clear-icon": {
    position: "absolute",
    right: 2,
    color: "#000",
    cursor: "pointer",
    top: 2,
    fontSize: 24,
  },
  ".upload-btn": {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "var(--gray-100)",
    padding: "8px 10px",
    borderRadius: 16,
    color: "var(--gray-700)",
    textTransform: "initial",
    lineHeight: "20px",
    svg: {
      width: 10,
      heigth: 10,
    },
  },
});

export default memo(UploadFiles);
