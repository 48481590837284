import { Box, BoxProps, styled } from "@mui/material";
import { FILE_DOMAIN, IMG_DOMAIN } from "constants/schemas";
import { useEffect, useState, useRef } from "react";
import { theme } from "theme";

const HtmlViewer = ({
  content = "",
  isHtmlNormal = false,
  ...otherProps
}: { content: string, isHtmlNormal?: boolean } & BoxProps) => {
  const [updatedHtml, setUpdateHtml] = useState("");

  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    // Update all <h1> tags to <h2>
    const h1Elements = tempDiv.querySelectorAll("h1");
    h1Elements.forEach((h1) => {
      const h2 = document.createElement("h2");
      h2.innerHTML = h1.innerHTML; // Copy the inner HTML
      h1.replaceWith(h2); // Replace <h1> with <h2>
    });

    // Update <img> tags with non-absolute URLs
    const imgElements = tempDiv.querySelectorAll("img");
    imgElements.forEach((img) => {
      const currentSrc = img.getAttribute("src");
      if (
        currentSrc &&
        !currentSrc.startsWith("http") &&
        !currentSrc.startsWith("data:image/")
      ) {
        img.setAttribute("src", `${FILE_DOMAIN}${currentSrc}`);
      }
      img.setAttribute("loading", "lazy");
      img.setAttribute("style", "max-width: 100%; height: 100%;");
    });

    setUpdateHtml(tempDiv.innerHTML);
  }, [content]);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const adjustHeight = () => {
      if (iframeRef.current) {
        if (iframeRef.current && iframeRef.current.contentWindow) {
          if (iframeRef.current.contentWindow) {
            iframeRef.current.style.height = iframeRef.current.contentWindow.document.body.scrollHeight + "px";
          }
        }
      }
    };
    adjustHeight();
  }, [updatedHtml]);

  const updatedHtmlWithErrorHandler = `
  <script async>
    document.addEventListener("DOMContentLoaded", function () {
      document.querySelectorAll("img").forEach(img => {
        img.onerror = function() {
          this.style.display = "none";
        };
      });
    });
  </script>
  ${updatedHtml}
`;

  return (
    <>
      {isHtmlNormal && <StyledBox ref={iframeRef} {...otherProps} component="div" dangerouslySetInnerHTML={{ __html: updatedHtmlWithErrorHandler }} />}
      {!isHtmlNormal && <StyledBox {...otherProps} component="div">
        <iframe
          ref={iframeRef}
          title="html-viewer-iframe"
          srcDoc={updatedHtmlWithErrorHandler}
          style={{
            display: "block",
            width: "100%",
            height: "auto",
            overflow: "auto",
            border: "none",
            minHeight: "100px",
          }}
          onLoad={() => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
              iframeRef.current.style.height =
                iframeRef.current.contentWindow.document.body.scrollHeight + "px";
            }
          }}
        />
      </StyledBox>}
    </>
  );
};

const StyledBox = styled(Box)({
  fontFamily: "Inter",
  lineHeight: "1.43",
  overflowWrap: "anywhere",
  fontSize: '14px',
  "& p": {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    "&:has(img)": {
      textAlign: "center",
    },
    "p:has(a)": {
      textAlgin: "center",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: 480,
    margin: "0 auto",
  },
  "*": {
    maxWidth: "100%",
    float: "initial",
  },
  "table, th, td": {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  table: {
    margin: "0 auto",
  },
  ".ql-code-block": {
    whiteSpace: "wrap",
  },
  [theme.breakpoints.down('md')]:
  {
    fontSize: '12px',
  }
});

export default HtmlViewer;
