import { createContext, useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import { getUser } from "redux/selectors/user";
import { langStorage } from "helpers/localStorage";
import { getBrowserLanguage } from "helpers/browser";
import { translationMessages } from "i18n";

type LanguageType = "en" | "vi" | "zh";

const LanguageContext = createContext<{
  language: LanguageType;
  setLanguage: any;
}>({
  language: "vi",
  setLanguage: (value: string) => {},
});

export const useLanguageContext = () => useContext(LanguageContext);

const LanguageProvider = ({ children, ...restProps }: any) => {
  const [currentLanguage, setCurrentLanguage] = useState<LanguageType>("en");
  const user = useSelector(getUser);
  const { pathname } = window.location;

  useEffect(() => {
    const prepareLocalize = () => {
      if (pathname.includes("/en/")) {
        setCurrentLanguage("en");
        return;
      }
      if (pathname.includes("/vi/")) {
        setCurrentLanguage("vi");
        return;
      }

      setCurrentLanguage(
        (user?.is_logged_in && user?.language) ||
          langStorage.get("selected") ||
          getBrowserLanguage()
      );
    };
    prepareLocalize();
  }, [navigator.language, pathname, user?.language]);

  return (
    <IntlProvider
      {...restProps}
      locale={currentLanguage}
      messages={translationMessages[currentLanguage]}
      defaultLocale={"en"}
    >
      <LanguageContext.Provider
        value={{ language: currentLanguage, setLanguage: setCurrentLanguage }}
      >
        {children}
      </LanguageContext.Provider>
    </IntlProvider>
  );
};

export default LanguageProvider;
