import ReactPlayer from "react-player/lazy";

import { checkIsVideo } from "../../utils/helpers";
// import LoadedImage from "../LoadedImage";

const DynamicMedia = (props) => {
  const {
    url,
    videoProps = undefined,
    imgProps = undefined,
    onClick = undefined,
  } = props;
  return (
    <>
      {checkIsVideo(url) ? (
        <ReactPlayer
          url={url}
          width="fit-content"
          height="100%"
          controls
          {...videoProps}
          onClick={onClick}
        />
      ) : (
        <img src={url} onClick={onClick} alt="img" {...imgProps} loading="lazy"/>
      )}
    </>
  );
};

export default DynamicMedia;
