import { Stack, StackProps, styled } from "@mui/material";
import { Container } from "./Container";

export const Main = (props: StackProps) => {
  return (
    <StyledMain component="main" {...props}>
      <Container>
        <Stack spacing={1}>{props.children}</Stack>
      </Container>
    </StyledMain>
  );
};

const StyledMain = styled(Stack)(({ theme }) => ({
  backgroundColor: "var(--bg-color)",
  padding: "8px 0",
  [theme.breakpoints.down("md")]: {
    ".MuiContainer-root": {
      paddingLeft: 8,
      paddingRight: 8,
    },
    ".title": {
      fontSize: 16,
    },
  },
}));

// export default Main;
