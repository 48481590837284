import { useLocation } from "react-router-dom";
import { SEARCH_PARAMS } from "constants/routes";

export function objectToQueryString(obj: any) {
  const keys = Object.keys(obj);
  const keyValuePairs = keys
    .filter((key) => [undefined, null].indexOf(obj[key]) < 0)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
    });
  return keyValuePairs.join("&");
}

interface QueryParams {
  [key: string]: string | string[];
}

export const getAllDataInQueryString = (query: URLSearchParams) => {
  try {
    const queryParams: QueryParams = {};
    for (let [key, value] of query.entries()) {
      queryParams[key] = value;
    }
    return queryParams;
  } catch (error) {
    return {};
  }
};

export const useQueryParam = (param: string) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  return queryParams.get(param) || undefined;
};

export function parseSearchParams(search: string, outletFields: string[] = []) {
  const searchParams = new URLSearchParams(search);
  const param: any = {};
  searchParams.forEach((value, key) => {
    if (outletFields.includes(key)) {
      param[key] = value;
    } else {
      if (!param.filters) param.filters = {};
      param.filters[key] = value.includes(",") ? value.split(",") : [value];
    }
  });

  return param;
}

// export const addUtmParam = (url = "") => {
//   console.log('url 2', url);
//   if (!url) return url;

//   const parsedUrl = new URL(url);
//   const urlParams = new URLSearchParams(parsedUrl.search);
//   const localUtmSource = localStorage.getItem(SEARCH_PARAMS.utmSource);

//   if (!localUtmSource) return url;

//   const delimiter = urlParams.toString() ? "&" : "?";
//   return `${url}${delimiter}${SEARCH_PARAMS.utmSource}=${localUtmSource}`;
// };
export const addUtmParam = (url = "") => {
  if (!url) return url;

  try {
    // Nếu url không bắt đầu bằng http, thêm window.location.origin
    const fullUrl = url.startsWith("http") ? url : `${window.location.origin}${url}`;
    const parsedUrl = new URL(fullUrl);

    const urlParams = new URLSearchParams(parsedUrl.search);
    const localUtmSource = localStorage.getItem(SEARCH_PARAMS.utmSource);

    if (!localUtmSource) return url;

    const delimiter = urlParams.toString() ? "&" : "?";
    return `${url}${delimiter}${SEARCH_PARAMS.utmSource}=${localUtmSource}`;
  } catch (error) {
    console.error("Lỗi khi parse URL:", error);
    return url;
  }
};