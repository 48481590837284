import { PLAN_NAMES } from "constants/data";
import dayjs from "dayjs";
import { PlanDataType } from "types/common";
import { PlanType } from "types/farm-info";

export const FREE_PACKAGE = "Free";

export const isValidPlan = (plan: PlanDataType): boolean => {
  if (!plan) return false;

  const now = dayjs();
  return plan.is_valid &&
    plan.plan_name &&
    plan.plan_name !== FREE_PACKAGE &&
    dayjs(plan.end_date).isAfter(now)
    ? true
    : false;
};

export const isNotFreePlan = (plan: PlanDataType): boolean => {
  const validPlans = [PLAN_NAMES.advance, PLAN_NAMES.starter];
  return validPlans.indexOf(plan?.plan_name) > -1;
};

export const isPremiumBuyer = (is_featured: number) => is_featured === 1;

export const colorPremiumBuyer = (is_featured: number, is_verified: number) => {
  if(is_featured === 0 && is_verified === 0)return 'linear-gradient(270deg, #667085 0%, #98A2B3 99.03%)';
  else if (is_featured === 0 && is_verified === 1) return "linear-gradient(to left,#027B30 0%, #049F3F 100%)";
  else return "linear-gradient(90deg, #C4944C 6.42%, #B68040 33%, #F7D14E 51%, #A86B35 77%, #FAC068 99.99%)";
}

export const isCheckCardFarm = (plan: PlanType) => {  
  if (plan.plan_name === 'Advance') return 3;  
  if (plan.plan_name === 'Starter' || (plan.plan_name === 'trial' && plan.is_valid)) return 2; 
  else {
    if (plan.is_biz_verified === 1) return 1;
    else return 0
  } 
};

export const colorPremiumFarm = (isLevel: number) => {
  if(isLevel === 3)return 'linear-gradient(90deg, #720000 0%, #EC3A31 24.88%, rgba(233, 75, 38, 0.8) 48.38%, #A80000 77%, #B70B07 100%)';
  else if (isLevel === 2) return "linear-gradient(90deg, #C4944C 6.42%, #B68040 33%, #F7D14E 51%, #A86B35 77%, #FAC068 99.99%)";
  else if (isLevel === 1) return "linear-gradient(270deg, #027B30 1.3%, #049F3F 98.81%)";
  else return "linear-gradient(270deg, #667085 0%, #98A2B3 99.03%)";
}

export const isCheckCardProduct = (plan_name: string, is_biz_verified: number, is_verified_work_email: number) => {  
  if (plan_name === 'Advance') return 3;  
  if (plan_name === 'Starter' || (plan_name === 'trial')) return 2; 
  else {
    if (is_biz_verified === 1) return 1;
    else return 0
  } 
};