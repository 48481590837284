import { ButtonProps } from "@mui/material";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { encodeBase64 } from "helpers/base64";
import { objectToQueryString } from "helpers/search";
import useNavigate from "hooks/useNavigate";
import { MouseEvent } from "react";
import { useIntl } from "react-intl";
import GreenButton from "./GreenButton";
import { EmailIcon, SmEmailIcon } from "Assets/icons";

type InquiryData = {
  farm_id?: string;
  farm_item_id?: string;
  unit?: string;
  quantity?: number | string;
  slug?: string;
};

const SendInquiryButton = (
  props: ButtonProps & { data?: InquiryData; textId?: string, isFromSupplier?: boolean }
) => {
  const { data, textId = "inquiry_now", isFromSupplier } = props;
  const t = useIntl();
  const { history } = useNavigate();

  const onClickSendInquiry = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    let { farm_id, farm_item_id, quantity = "", unit = "", slug } = data || {};

    if (isFromSupplier) {
      history.push({
        pathname: slug,
        search: slug
          ? `isScroll=true`: "",
      });
    }
    else {
      history.push({
        pathname: PATHS.sendInquiry,
        search: farm_id
          ? `${SEARCH_PARAMS.farmId}=${encodeBase64(farm_id)}${farm_item_id
            ? "&" +
            objectToQueryString({
              [SEARCH_PARAMS.itemId]: encodeBase64(farm_item_id),
              [SEARCH_PARAMS.quantity]: encodeBase64(quantity),
              [SEARCH_PARAMS.unit]: encodeBase64(unit),
            })
            : ""
          }`
          : "",
      });
    };
  }
    return (
      <GreenButton
        onClick={onClickSendInquiry}
        endIcon={<SmEmailIcon stroke="#fff" />}
        {...props}
      >
        {t.formatMessage({ id: textId })}
      </GreenButton>
    );
  };

  export default SendInquiryButton;
