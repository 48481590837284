import { useState } from "react";
import { Box, Skeleton } from "@mui/material";

const LoadedImage = ({ src, styles, ...otherProps }) => {
  const [loadedImg, setLoadedImg] = useState(false);

  const handleLoadImg = (e) => {
    setLoadedImg(true);
  };

  return (
    <>
      <img
        src={src}
        alt="img"
        style={{ ...styles, ...(loadedImg ? {} : { display: "none" }) }}
        onLoad={handleLoadImg}
        {...otherProps}
        loading="lazy"
      />
      {!loadedImg && (
        <Box sx={{ ...styles }} p={1}>
          <Skeleton variant="rounded" height="100%" />
        </Box>
      )}
    </>
  );
};

export default LoadedImage;
