import { Box, Dialog, Divider, IconButton, Stack } from "@mui/material";

import { StyledHeader,  } from "./Header.styles";
import FreshdiLogo from "../FreshdiLogo";
import { PATHS } from "constants/routes";
import { memo, useState } from "react";

import IntlMsg from "../IntlMsg";
import Row from "../Row";
import { Container } from "../Container";
import { HeaderServices, Img, SelectLanguage, TextButton } from "Components";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { IoMdMenu } from "react-icons/io";
import { MdClose } from "react-icons/md";
import useNavigate from "hooks/useNavigate";
import { POST_RFQ } from "../../Assets/icons";
import HeaderLinks from "./HeaderLinks";
import { StyledMobileHeader } from "./MobileMenuHeader";

type Props = {};

const Header = (props: Props) => {
  const { downTabletBreakpoint } = useMediaBreakpoints();
  const [showDialog, setShowDialog] = useState(false);

  const onCloseDrawer = () => {
    setShowDialog(false);
  };

  const onClickMenu = () => {
    setShowDialog(true);
  };

  return (
    <StyledHeader>
      <Container>
        <Row justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            {downTabletBreakpoint && (
              <IconButton onClick={onClickMenu}>
                <IoMdMenu />
              </IconButton>
            )}
            <FreshdiLogo />
          </Stack>

          <SelectLanguage showLabel={!downTabletBreakpoint} />
        </Row>
      </Container>

      {!downTabletBreakpoint && <Divider sx={{ mt: 2 }} />}

      {!downTabletBreakpoint && (
        <Box pt={2}>
          <Container>
            <Row justifyContent="space-between">
              <HeaderLinks />
              <HeaderServices />
            </Row>
          </Container>
        </Box>
      )}

      <Dialog fullScreen open={showDialog} onClose={onCloseDrawer}>
        <MobileHeader onClose={onCloseDrawer} />
      </Dialog>
    </StyledHeader>
  );
};

const MobileHeader = ({ onClose }: { onClose?: any }) => {
  const { openNewTab } = useNavigate();

  const onClickCreateQuickRFQ = () => {
    openNewTab(PATHS.postRfq);
  };

  return (
    <StyledMobileHeader>
      <Row justifyContent="space-between" p={2} pb={1}>
        <IconButton onClick={onClose}>
          <MdClose />
        </IconButton>
        <FreshdiLogo />
        <div style={{ width: 32 }} />
      </Row>

      <Stack spacing={2} className="mobileheader">
        <HeaderLinks inrow px={2}/>
        <Divider />
        <Stack spacing={2} px={2}>
          <TextButton
            className="quickrfq_button"
            onClick={onClickCreateQuickRFQ}
            startIcon={<Img src={POST_RFQ} alt="post rfq" />}
          >
            <IntlMsg id="QuickPostRFQ" />
          </TextButton>
        </Stack>
      </Stack>
    </StyledMobileHeader>
  );
};

export default memo(Header);
