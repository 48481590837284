import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "redux/selectors/user";
import useNavigate from "./useNavigate";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { useLoading } from "providers/loading";
import { apiGetMe, apiLogoutUser } from "services/api/user";
import { logoutUser, setUser } from "redux/actions/user";
import { decodeBase64, encodeBase64 } from "helpers/base64";
import { SOURCING_DOMAIN } from "constants/schemas";
// import { routes } from "helpers/localStorage";

const useAuth = () => {
  const user = useSelector(getUser);
  const {
    history,
    isMatchPath,
    useSearchParams,
    directKeepSearch,
    openNewTab,
  } = useNavigate();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const isLoggedIn = useMemo(() => user?.is_logged_in, [user?.is_logged_in]);

  const onDirectLogin = () => {
    let currentUrl = window.location.href;
    let directUrl = useSearchParams.get(SEARCH_PARAMS.directUrl);
    directKeepSearch(
      PATHS.login,
      !directUrl
        ? `${SEARCH_PARAMS.directUrl}=${directUrl || encodeBase64(currentUrl)}`
        : ""
    );
  };

  const onDirectRegister = useCallback(() => {
    let currentUrl = window.location.href;
    let directUrl = useSearchParams.get(SEARCH_PARAMS.directUrl);
    history.push({
      pathname: PATHS.register,
      search: `${SEARCH_PARAMS.directUrl}=${
        directUrl || encodeBase64(currentUrl)
      }`,
    });
  }, []);

  const onClickLoginSupplier = () => {
    openNewTab(
      `${PATHS.login}?${SEARCH_PARAMS.directUrl}=${encodeBase64(
        SOURCING_DOMAIN
      )}`
    );
  };

  const onLogout = async () => {
    setLoading(true);
    return await apiLogoutUser()
      .then((res) => {
        dispatch(logoutUser());
        setLoading(false);
        const matchDirectHome =
          isMatchPath("/user") ||
          isMatchPath(PATHS.paymentBuyer) ||
          isMatchPath(PATHS.paymentSupplier);
        if (matchDirectHome) {
          history.push(PATHS.home);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onGetUserInformation = useCallback(async () => {
    return apiGetMe().then((res) => {
      setLoading(false);
      let newUserData = res?.data?.message?.data || {};
      newUserData["freshdi_customer_id"] = newUserData?.name;
      dispatch(setUser({ ...newUserData, userStatus: "fulfilled" }));
    });
  }, [dispatch, history, useSearchParams]);

  const onDirectNextPageOfLogin = useCallback(() => {
    let directPath = useSearchParams.get(SEARCH_PARAMS.directUrl);
    if (directPath) {
      window.location.href = decodeBase64(directPath);
    } else {
      history.push(PATHS.home);
    }
  }, []);

  return {
    isLoggedIn,
    onDirectLogin,
    onLogout,
    onGetUserInformation,
    onDirectNextPageOfLogin,
    onDirectRegister,
    onClickLoginSupplier,
  };
};

export default useAuth;
