import { Button, ButtonProps, styled } from "@mui/material";
import { forwardRef } from "react";

const TextButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    return <StyledTextButton variant="text" {...props} ref={ref} />;
  }
);

export const StyledTextButton = styled(Button)(({ theme }) => ({
  textTransform: "initial",
  padding: 0,
  fontWeight: 600,
  fontSize: 14,
  color: "var(--gray-color)",
  ".MuiButton-startIcon": {
    marginLeft: 0,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export default TextButton;
