import { Box, Menu, Paper, Stack, StackProps, styled } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { DIRECT_PATHS, PATHS } from "constants/routes";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { SmText } from "../Text";
import { CartIcon, MenuIcon } from "Assets/icons";
import { useTranslation } from "hooks/useTranslation";
import { COLORS } from "constants/layout";
import { TextButton } from "Components/button/index";
import { useCommonData } from "providers/common-data";
import { ALink, Link } from "Components/link";
import { OptionType } from "types";
import { useHistory, useParams } from "react-router-dom";
import { ParamsType } from "types/params";

const HeaderLinks = (props: StackProps & { inrow?: boolean }) => {
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();
  const t = useTranslation();
  const categoryButtonRef = useRef<HTMLButtonElement | null>(null);
  const { appConfigs } = useCommonData();
  const { categoryId } = useParams<ParamsType>();
  const history = useHistory();

  const [openCategories, setOpenCategories] = useState(false);

  const categorries = useMemo(
    () => appConfigs?.allCates,
    [appConfigs?.allCates]
  );

  const handleMouseEnter = () => setOpenCategories(true);

  const handleMouseLeave = () => setOpenCategories(false);

  const handleBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      handleMouseLeave();
    }
  };

  const chunkItems = (arr: OptionType[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const itemChunks = chunkItems(categorries || [], 17);

  useEffect(() => {
    handleMouseLeave();
  }, [categoryId]);

  if (!isMobile) {
    return (
      <StyledHeaderLinks direction="row" spacing={1} {...props}>
        <TextButton
          className="category-btn"
          startIcon={<MenuIcon />}
          ref={categoryButtonRef}
          onClick={handleMouseEnter}
        >
          {t("AllCategories")}
        </TextButton>
        <ALink href={PATHS.buyleads} target="_blank" rel="noreferrer">
          <TextButton startIcon={<CartIcon />} className="leads-btn">
            {t("buying_leads")}
          </TextButton>
        </ALink>

        <Menu
          open={openCategories}
          onClose={handleMouseLeave}
          anchorEl={categoryButtonRef.current}
          onBlur={handleBlur}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          MenuListProps={{
            disablePadding: true,
          }}
          slotProps={{ paper: { sx: { p: 0, mt: 1 } } }}
        >
          <StyledDropdown>
            <Box display="flex" gap={2}>
              {itemChunks.map((chunk, index) => (
                <Stack key={index} spacing={0.5}>
                  {chunk?.map((category, i) => (
                    <Link
                      key={i}
                      to={DIRECT_PATHS.categorySupplier(category.value)}
                      className="category-text"
                      onClick={(event) => {
                        event.preventDefault();
                        handleMouseLeave();
                        setTimeout(() => {
                          history.push(
                            DIRECT_PATHS.categorySupplier(category.value)
                          );
                        }, 100);
                      }}
                    >
                      <SmText>{category.label}</SmText>
                    </Link>
                  ))}
                </Stack>
              ))}
            </Box>
          </StyledDropdown>
        </Menu>
      </StyledHeaderLinks>
    );
  }

  return (
    <StyledHeaderLinks
      direction="column"
      alignItems="flex-start"
      {...props}
    ></StyledHeaderLinks>
  );
};

const StyledDropdown = styled(Paper)(({ theme }) => ({
  padding: 16,
  ".category-text": {
    padding: "4px 0",
    width: 200,
    "&:hover": {
      color: COLORS.green[600],
    },
  },
}));

const StyledHeaderLinks = styled(Stack)(({ theme }) => ({
  ".category-btn, .leads-btn": {
    fontWeight: 500,
    fontSize: 14,
  },
  ".category-btn": {
    color: COLORS.gray[500],
  },
  ".leads-btn": {
    color: COLORS.green[600],
  },
  [theme.breakpoints.down("md")]: {
    ".label": {
      fontSize: 14,
    },
  },
}));

export default HeaderLinks;
