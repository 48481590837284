import {
  apiGetCustomerConversations,
  apiGetTwlkAccessToken,
} from "services/api/chat";
import { LOCALSTORAGE_KEYS, chatStorage } from "helpers/localStorage";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onOpenChatContainer,
  // onReloadHeaderChat,
  setChatClient,
  setChatConverstations,
  setTwlIdentify,
  setTwlToken,
} from "redux/actions/chat";
import { Client, Conversation, Message } from "@twilio/conversations";
import {
  getChatClient,
  getConversations,
  getReloadHeaderChat,
  getTwlIdentity,
  getTwlToken,
} from "redux/selectors/chat";
import React, { ReactNode } from "react";
import useChat from "hooks/useChat";
import useUser from "hooks/useUser";
import { AppConversationType } from "types/chat";
import { getMessageAttributes } from "helpers/chat";
import { CHAT_LAYOUT } from "constants/layout";

type Props = {
  children?: ReactNode;
};

const ChatContext = createContext({
  onFetchConversations: () => Promise.resolve(),
  isLoading: true,
});

const ChatProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const twlToken = useSelector(getTwlToken);
  const { isLoggedIn } = useUser();
  const {
    onClearChatKey,
    getConversationById,
    getUnreadCountForConversation,
    twlIdentify,
  } = useChat();
  const chatClient: Client = useSelector(getChatClient);
  const reloadHeaderChat = useSelector(getReloadHeaderChat);
  const conversations: AppConversationType[] =
    useSelector(getConversations) || [];
  const twlIdentity = useSelector(getTwlIdentity);

  const [pagination, setPagination] = useState({
    limit: CHAT_LAYOUT.conversationsLimit,
    offset: 0,
    hasMore: true,
  });
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setStoreTwl = (twlk: string, twlid: string) => {
    dispatch(setTwlToken(twlk));
    dispatch(setTwlIdentify(twlid));
  };

  const setLocalStorerageTwl = (twlk: string, twlid: string) => {
    chatStorage.set(LOCALSTORAGE_KEYS.twlk, twlk);
    chatStorage.set(LOCALSTORAGE_KEYS.twlid, twlid);
  };

  const getTwlk = async () => {
    const twlk = chatStorage.get(LOCALSTORAGE_KEYS.twlk);
    const twlid = chatStorage.get(LOCALSTORAGE_KEYS.twlid);
    if (!twlk || !twlid) {
      const data = await apiGetTwlkAccessToken();
      const newTwlk = data.data?.message?.data?.token;
      const newTwlid = data.data?.message?.data?.identity;
      if (newTwlk) {
        setLocalStorerageTwl(newTwlk, newTwlid);
        setStoreTwl(newTwlk, newTwlid);
      }
    } else {
      setStoreTwl(twlk, twlid);
    }
  };

  const onResetTwl = async () => {
    setLocalStorerageTwl("", "");
    await getTwlk();
  };

  const onFetchConversations = async () => {
    if (!pagination.hasMore) return;
    setIsLoading(true);
    const resData = await apiGetCustomerConversations(
      pagination.limit,
      pagination.offset
    );
    const nextConversations = resData.data?.message?.data || [];
    const nextConversationPromises = nextConversations.map(
      async (conversation: AppConversationType) => {
        const supplierDetail = (conversation.participants || []).find(
          (paricipant) => paricipant.identity !== twlIdentity
        );
        const fullConversation = await getConversationById(
          conversation.conversation_sid
        );
        if (fullConversation) {
          const messages = await fullConversation.getMessages(1);
          const unreadMessagesCount = await getUnreadCountForConversation(
            fullConversation
          );
          let lastMessage =
            messages.items.length > 0 ? messages.items[0] : null;
          if (lastMessage) {
            const messageAttributes = getMessageAttributes(lastMessage);
            const hideIdentityList: string[] = messageAttributes?.hidden || [];
            if (hideIdentityList.indexOf(twlIdentify) > -1) {
              lastMessage = null;
            }
          }
          return {
            ...conversation,
            supplierDetail,
            lastMessage,
            unreadMessagesCount,
            date_updated: lastMessage?.dateUpdated,
          };
        }
      }
    );
    // const conversationsData = await Promise.all(nextConversationPromises);
    const newConversations = (await Promise.all(nextConversationPromises)).filter(Boolean); 

    const uniqueConversationDataMap =  new Map();
    [...conversations, ...newConversations].forEach((conv) => {
      uniqueConversationDataMap.set(conv.conversation_sid, conv);
    })

    const uniqueConversationData = Array.from(uniqueConversationDataMap.values()).sort(
      (a,b) => b.date_updated - - a.date_updated
    )
    setIsInitialLoadComplete(true);
    dispatch(
      // setChatConverstations([
      //   ...conversations,
      //   ...conversationsData.sort((a, b) => b.date_updated - a.date_updated),
      // ])
      setChatConverstations(uniqueConversationData)
    );
    setPagination((prev) => ({
      ...prev,
      offset: prev.offset + prev.limit,
      hasMore: nextConversations.length >= prev.limit,
    }));
    setIsLoading(false);
  };

  const conversationAddedListener = (conversation: Conversation) => {
    // setConversations((prevConversations) => {
    //   if (
    //     !prevConversations.some(
    //       (conv) => conv.conversation_sid === conversation.sid
    //     )
    //   ) {
    //     return [...prevConversations];
    //   }
    //   return prevConversations;
    // });
  };

  useEffect(() => {
    if (isLoggedIn) {
      getTwlk();
    } else {
      onClearChatKey();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (twlToken) {
      try {
        const client = new Client(twlToken);
        client.on("stateChanged", async (state) => {
          if (state === "initialized") {
            dispatch(setChatClient(client));
          }
          if (state === "failed") {
            onResetTwl();
          }
        });
        client.on("tokenAboutToExpire", async () => {
          onResetTwl();
        });
        client.on("tokenExpired", async () => {
          onResetTwl();
        });
        client.on("conversationAdded", (conversation: Conversation) => {
          // if (isInitialLoadComplete) {
          //   onFetchConversations();
          // }
        });
        client.on("messageAdded", async (message: Message) => {
          dispatch(onOpenChatContainer(message.conversation));
        });

        return () => {
          client?.removeAllListeners();
        };
      } catch (error) {
        onResetTwl();
      }
    }
  }, [twlToken]);

  useEffect(() => {
    if (chatClient && isLoggedIn) {
      onFetchConversations();
    }
  }, [chatClient, isLoggedIn, reloadHeaderChat]);

  useEffect(() => {
    if (chatClient) {
      chatClient.on?.("messageAdded", onFetchConversations);
      chatClient.on("conversationAdded", conversationAddedListener);

      return () => {
        chatClient.off?.("messageAdded", onFetchConversations);
        chatClient.off?.("conversationAdded", conversationAddedListener);
      };
    }
  }, [chatClient, conversations]);

  return (
    <ChatContext.Provider value={{ onFetchConversations, isLoading }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);

export default ChatProvider;
