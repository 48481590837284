import {
  styled,
  Stack,
  Dialog,
  LinearProgress,
  Button,
  MenuItem,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SmText, SmTextMd, XsText, XsTextMd } from "Components/Text";
import { APP_ICONS, CheckIcon, DeleteIcon, RegenerateIcon } from "Assets/icons";
import Row from "Components/Row";
import { Img } from "Components/Img";
import { BaseButton } from "Components/Button";
import Disclaimer from "Components/Disclaimer";
import { FiInfo } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { setGeneratingAI } from "redux/actions/layout";
import { FakeInput } from "Components/Input";
import { removeHTMLTags } from "helpers/string";
import { COLORS } from "constants/layout";
import useBreakpoints from "hooks/useBreakpoint";
import { DisclaimerButton } from "Components/button/index";

type AIGenerateInputProps = {
  useForm: any;
  fieldName: string;
  label?: string;
  requiredlabel?: boolean;
  children?: ReactNode;
  isValid?: boolean;
  apiGenerate?: any;
  onClickGenerate?: () => void;
  onClickApplyBonus?: () => void;
  removeHTML?: boolean;
};

const AIGenerateInput = ({
  useForm,
  fieldName,
  label,
  requiredlabel,
  children,
  isValid,
  apiGenerate,
  onClickGenerate = () => {},
  onClickApplyBonus = () => {},
  removeHTML = false,
}: AIGenerateInputProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoints();

  const { getValues, setValue } = useForm;
  const [isGenerating, setIsGenerating] = useState(false);
  const [openGenerate, setOpenGenerate] = useState(false);
  const [progress, setProgress] = useState(78);
  const [tmpValue, setTmpValue] = useState<string>("");
  const [openMobileActions, setOpenMobileActions] = useState(false);

  const onClickDiscard = () => {
    setOpenGenerate(false);
    dispatch(setGeneratingAI(false));
  };

  const onClickApply = () => {
    setValue(fieldName, (getValues(fieldName) || "") + "\n" + tmpValue, {
      shouldValidate: true,
    });
    onClickDiscard();
    onClickApplyBonus?.();
  };

  const onClickAction = (action: string) => {
    switch (action) {
      case "apply":
        onClickApply();
        break;
      case "regenerate":
        onGenerateRequest();
        break;

      case "discard":
        onClickDiscard();
        break;
      default:
        break;
    }
    if (isMobile) {
      onCloseMobileActions();
    }
  };

  const onGenerateRequest = () => {
    onClickGenerate?.();
    if (isValid && apiGenerate) {
      dispatch(setGeneratingAI(true));
      setIsGenerating(true);
      setProgress(0);
      setOpenGenerate(true);
      apiGenerate()
        .then((res: any) => {
          const data = res.data.message?.data;
          setTmpValue(removeHTML ? removeHTMLTags(data) : data);
          setProgress(100);
          if (isMobile) {
            setOpenMobileActions(true);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setOpenGenerate(false);
        })
        .finally(() => {
          setIsGenerating(false);
        });
    }
  };
  const actions = [
    {
      label: intl.formatMessage({ id: "apply" }),
      icon: <CheckIcon />,
      value: "apply",
      className: "green-btn",
    },
    {
      label: intl.formatMessage({ id: "regenerate" }),
      icon: <RegenerateIcon />,
      value: "regenerate",
    },
    {
      label: intl.formatMessage({ id: "discard" }),
      icon: <DeleteIcon />,
      value: "discard",
    },
  ];

  const onCloseMobileActions = () => {
    setOpenMobileActions(false);
    setOpenGenerate(false);
  };

  useEffect(() => {
    if (progress < 100) {
      const autoCountProgress = setInterval(() => {
        setProgress((progress) => Math.min(progress + 10, 90));
      }, 1000);

      return () => clearInterval(autoCountProgress);
    }
  }, [isGenerating]);

  return (
    <Stack spacing={1} className="input">
      <Row spacing={1}>
        <SmText className={"input-label" + (requiredlabel ? " required" : "")}>
          {label}
        </SmText>
        <GenerateButton onClick={onGenerateRequest}>
          {intl.formatMessage({
            id: isGenerating ? "generating" : "generate_request",
          })}
          <Img src={APP_ICONS.sparkless} />
        </GenerateButton>
      </Row>

      <div style={{ display: openGenerate ? "none" : "block" }}>{children}</div>

      {openGenerate && (
        <StyledAIGenerateInput
          justifyContent={isGenerating ? "center" : "flex-start"}
          className="generate-box"
        >
          {progress === 100 && (
            <Stack className="generate-detail" spacing={1}>
              {!isMobile && (
                <Row justifyContent="space-between">
                  <Row spacing={1} className="actions">
                    {actions.map((action, id) => (
                      <Button
                        onClick={() => onClickAction(action.value)}
                        key={id}
                        className={action.className}
                      >
                        {action.icon}
                        {action.label}
                      </Button>
                    ))}
                  </Row>
                  <DisclaimerButton />
                </Row>
              )}
              <XsText className="generate-text">
                {removeHTMLTags(tmpValue)}
              </XsText>
              {isMobile && <DisclaimerButton />}
            </Stack>
          )}

          {isGenerating && (
            <Row justifyContent="center" width="100%">
              <LinearProgress
                variant="determinate"
                className="progress"
                value={progress}
              />
              <SmTextMd>{progress}%</SmTextMd>
            </Row>
          )}

          {openMobileActions && (
            <div className="mobile-actions card">
              {actions.map((action, id) => (
                <MenuItem
                  onClick={() => onClickAction(action.value)}
                  key={id}
                  sx={{ p: 1, minHeight: 34 }}
                >
                  <Row>
                    {action.icon}
                    <XsTextMd>{action.label}</XsTextMd>
                  </Row>
                </MenuItem>
              ))}
            </div>
          )}
        </StyledAIGenerateInput>
      )}
    </Stack>
  );
};

export default AIGenerateInput;

const GenerateButton = styled(BaseButton)({
  borderRadius: 13,
  width: "fit-content",
  padding: "5px 12px",
  lineHeight: 1,
  fontSize: 10,
  border: "none",
  "&,:hover": { backgroundColor: COLORS.green[600] },
  color: "#fff",
  img: {
    height: 12,
    marginLeft: 4,
  },
});

const StyledAIGenerateInput = styled(FakeInput)({
  zIndex: 11,
  flex: 1,
  minHeight: 48,
  maxHeight: 240,
  backgroundColor: "#fff",
  position: "relative",
  ".input": {
    width: "100%",
  },
  ".progress": {
    width: "100%",
    maxWidth: 278,
    borderRadius: 4,
    height: 8,
    ".MuiLinearProgress-bar": {
      backgroundColor: "#008934",
      borderRadius: 4,
    },
  },
  ".generate-detail": {
    width: "100%",
    minHeight: "100%",
  },
  ".generate-text": {
    padding: 8,
    backgroundColor: "rgb(229, 246, 253)",
    overflow: "hidden",
    overflowY: "scroll",
  },

  ".actions": {
    button: {
      fontSize: 12,
      textTransform: "initial",
      border: `1px solid ${COLORS.gray[300]}`,
      padding: "4px 8px",
      borderRadius: 8,
      alignItems: "center",
      color: COLORS.gray[700],
      svg: {
        marginRight: 4,
      },
      "&.green-btn": {
        color: "#fff",
        backgroundColor: COLORS.green[600],
        "svg path": {
          stroke: "#fff",
        },
      },
    },
  },
  ".mobile-actions": {
    position: "fixed",
    bottom: 16,
    left: 16,
    width: "calc(100% - 32px)",
    border: `1px solid ${COLORS.green[600]}`,
  },
});
