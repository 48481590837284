import { Backdrop, styled } from "@mui/material";
import { APP_IMGS } from "Assets/images";

const CircularLoading = ({ open }: { open: boolean; onClose: () => void }) => {
  return (
    <StyledBackdrop open={open} onClick={() => {}}>
      <div className="backgrop_content">
        <img src={APP_IMGS.common.desktop["freshdiLoading"]} alt="load" loading="lazy"/>
      </div>
    </StyledBackdrop>
  );
};

const StyledBackdrop = styled(Backdrop)({
  backgroundColor: "rgba(0, 0, 0, 0.85)",
  "& .backgrop_content": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:focus": {
      outline: "none",
    },
  },
  zIndex: 1305,
});

export { CircularLoading };
