import { ReactNode, useEffect } from "react";
import { LoadingProvider } from "./loading";
import NotifyProvider from "./notify";
import { CommonDataProvider } from "./common-data";
import { LayoutProvider } from "./layout";
import NotificationHandler from "helpers/notification";
import ScrollToTop from "Components/ScrollToTop";
import ChatProvider from "./chat";
import { SEARCH_PARAMS } from "constants/routes";
import { getCookie, setCookie } from "helpers/cookie";
import LanguageProvider from "Components/LanguageProvider";
import { SocketProvider } from "./socket";

const AppProvider = ({ children }: { children: ReactNode }) => {
  const getUtmSource = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get(SEARCH_PARAMS.utmSource);
    const localUtmSource = getCookie(SEARCH_PARAMS.utmSource);
    if (!localUtmSource) {
      setCookie(SEARCH_PARAMS.utmSource, utmSource || "origin", 30);
    }
  };

  useEffect(() => {
    getUtmSource();
  }, []);

  return (
    <LanguageProvider>
      <CommonDataProvider>
        <ChatProvider>
          <LayoutProvider>
            <LoadingProvider>
              <SocketProvider>
                <NotifyProvider>
                  {children}

                  {/* Scroll top */}
                  <ScrollToTop />

                  <NotificationHandler />
                </NotifyProvider>
              </SocketProvider>
            </LoadingProvider>
          </LayoutProvider>
        </ChatProvider>
      </CommonDataProvider>
    </LanguageProvider>
  );
};

export default AppProvider;
