import { styled } from "@mui/material";
import { Container } from "Components/Container";

export const StyledFooter = styled(Container)({
  ".footer_main": {
    width: "100%",
  },
  p: {
    color: "var(--gray-500)",
  },
  ".footer_title": {
    color: "#000000",
  },
  ".footer_title, .footer_link p": {
    fontWeight: 400,
    fontSize: 14,
  },
  ".footer_link": {
    textDecorationColor: "var(--gray-500)",
  },
  ".lastestFooter": {
    "& .forFarmText": {
      "& span": {
        marginLeft: 4,
        color: "var(--green-text-color)",
      },
      fontSize: "12px",
    },
    "& .padding": "24px 0",
    "& p": {
      color: "#8C8C8C",
    },
  },
});
