import React, { forwardRef } from "react";
import { Rating, RatingProps, styled } from "@mui/material";
import { EmptyStarIcon, StarIcon } from "Assets/icons/star.icon";

const StarRating = forwardRef<HTMLSpanElement, RatingProps>((props, ref) => {
  return (
    <StyledRating
      icon={<StarIcon />}
      emptyIcon={<EmptyStarIcon />}
      {...props}
      ref={ref} // Pass the ref to the styled Rating
    />
  );
});

const StyledRating = styled(Rating)(({ theme }) => ({
  ".MuiRating-icon svg": {
    width: 18,
    height: 17,
  },
  [theme.breakpoints.down("md")]: {
    ".MuiRating-icon svg": {
      width: 15,
      height: 14,
    },
  },
}));

export default StarRating;
