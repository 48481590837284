export const getTwlToken = (state: any) => state?.chatReducer?.twlToken;

export const getTwlIdentity = (state: any) => state?.chatReducer?.twlIdentify;

export const getChatClient = (state: any) => state?.chatReducer?.chatClient;

export const getConversations = (state: any) =>
  state?.chatReducer?.conversations;

export const getOpenChatContainer = (state: any) =>
  state?.chatReducer?.openChatContainer;

export const getChatList = (state: any) => state?.chatReducer?.chatList;

export const getReloadHeaderChat = (state: any) =>
  state?.chatReducer?.reloadHeaderChat;

export const getMobileChatDetail = (state: any) =>
  state?.chatReducer?.mobileChatDetail;

export const getOpenChat = (state: any) =>
  state?.chatReducer?.isOpenChat;