import { Stack, StackProps } from "@mui/material";
import { APP_IMGS } from "Assets/images";

const FreshdiLoading = (props: StackProps) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100%"
      minHeight="85vh"
      {...props}
    >
      <img src={APP_IMGS.common.desktop["freshdiLoading"]} alt="icon" loading="lazy"/>
    </Stack>
  );
};

export default FreshdiLoading;
