import { Divider, NoSsr } from "@mui/material";
import { StyledCenterStack, StyledHeader } from "./Header.styles";
import FreshdiLogo from "../FreshdiLogo";
import { memo, useEffect, useState } from "react";

import Row from "../Row";
import { Container } from "../Container";
import { HeaderServices, SelectLanguage } from "Components";
import useAuth from "hooks/useAuth";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import HeaderSearch from "./HeaderSearch";
import HeaderLinks from "./HeaderLinks";
import { useIntl } from "react-intl";
import AuthBar from "../infos/AuthBar";
import { GetQuotesButton, RegisterButton } from "Components/button/index";
import AiAgentButton from "Components/button/AiAgentButton";

type Props = {
  hideSearch?: boolean;
  isHomePage?: boolean;
};

const Header = ({ hideSearch, isHomePage }: Props) => {
  const t = useIntl();
  const { isMobile } = useMediaBreakpoints();
  const { isLoggedIn } = useAuth();
  const [isBottomBoxVisible, setIsBottomBoxVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsBottomBoxVisible(false);
      } else {
        setIsBottomBoxVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <NoSsr>
      <StyledHeader>
        <Container>
          <StyledCenterStack spacing={1} justifyContent="space-between">
            {isMobile && (
              <>
                <div style={{ width: 68 }} />
                <FreshdiLogo />
              </>
            )}
            {isMobile && (
              <Row spacing={0.5} justifyContent="flex-end">
                {/* {isLoggedIn && <Notification />} */}
                <SelectLanguage
                  buttonProps={{ sx: { justifyContent: "flex-start" } }}
                  showLabel={false}
                />
                <AuthBar hideUserName />
              </Row>
            )}

            {!isMobile && (
              <>
                <Row spacing={2} flex={1}>
                  <FreshdiLogo />
                  {!hideSearch && !isHomePage && <HeaderSearch />}
                </Row>
                <StyledCenterStack spacing={1} justifyContent="flex-end">
                  <AiAgentButton />
                  <GetQuotesButton />
                  <SelectLanguage buttonProps={{ className: "app-link-btn" }} />
                  <Row ml={1}>
                    {/* {isLoggedIn && <Notification />} */}
                    <AuthBar />
                    {!isLoggedIn && <RegisterButton />}
                  </Row>
                </StyledCenterStack>
              </>
            )}
          </StyledCenterStack>
          {!hideSearch && isMobile && <HeaderSearch sx={{ mt: 1 }} />}
        </Container>

        {/* Bottom header */}
        {!isMobile && (
          <>
            <Divider sx={{ mt: 1.5 }} />
            <Container
              className={
                "bottom-box " + (isBottomBoxVisible ? "visible" : "hidden")
              }
            >
              <Row justifyContent="space-between">
                <HeaderLinks />
                <HeaderServices />
              </Row>
            </Container>
          </>
        )}
      </StyledHeader>
    </NoSsr>
  );
};

export default memo(Header);
