export const getCountryFlag = (country: string) => ({
  src: `https://flagcdn.com/w20/${country}.webp`,
});

export const convertLangToCountry = (lang: string) => {
  switch (lang) {
    case "vi":
      return "vn";

    case "vn":
      return "vn";
    
    case "zh":
      return "cn";

    default:
      return "gb";
  }
};
